/*eslint-disable*/
import React from "react";
// react components for routing our app without refresh
import { Link } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";


import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";
import { Button } from "@material-ui/core";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const classes = useStyles();
  return (
    <div className="home_page_menu">
      <List className={classes.list + " main_navbar"}>
      <ListItem className={classes.listItem}>
      <div class="dropdown">
      <a class="nav-link dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        Earn
      </a>
      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
       
        <Link to="/farms" color="transparent" class="dropdown-item">Stake LP</Link>
        <Link to="/pools" color="transparent" class="dropdown-item">Stake MPAD</Link>

      </div>
    </div>
    </ListItem>
      <ListItem className={classes.listItem}>
         <a href="https://multipad.co/launch-app" target="_blank" color="transparent" className="nav-link">Launch App</a>
        </ListItem>
       {/* <ListItem className={classes.listItem}>
         <Link to="" color="transparent" className="nav-link">Staking</Link>
        </ListItem> */}
        <ListItem className={classes.listItem}>
         <a href="https://docs.google.com/forms/d/e/1FAIpQLSerz7SIGT-XG91WMBI1-bVpmouiBVNt0B1iW8z1VdLkIxRrHQ/viewform" color="transparent" className="nav-link" target="_blank">Launch Your Product</a>
        </ListItem>
        <ListItem className={classes.listItem}>
         <a href="https://multipad.gitbook.io/multipad/" color="transparent" className="nav-link" target="_blank">Documentation</a>
        </ListItem>
        <ListItem className={classes.listItem}>
         <a href="https://multipad.co/static/media/OnePager.445e9d69.pdf" target="_blank" color="transparent" className="nav-link">One Pager</a>
        </ListItem>
        <Hidden mdUp>
        <ListItem className={classes.listItem}>
         <Link to="" color="transparent" className="nav-link text-uppercase">Connect Wallet</Link>
        </ListItem>
        <div className="footer-icons justify-content-center">
              <a href="">
              <i class="fab fa-twitter"></i>
              </a>
              <a href="">
              <i class="fas fa-paper-plane"></i>
              </a>
              <a href="">
              <img src={require("../../assets/images/Medium_i.svg")} alt="logo" className="img-fluid img_width_s_log" />
              </a>
              </div>
        </Hidden>
        {/* <ListItem className={classes.listItem}>
          <Button className="menu_btn"><Link to="/register" color="transparent" className="nav-link">Join Now</Link></Button>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Button className="menu_btn"><Link to="/login" color="transparent" className="nav-link">Login</Link></Button>
        </ListItem> */}
      </List>
    </div>
  );
}

import React from "react";
import ReactDOM from "react-dom";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import './index.css';

// pages for this product
// import Home from "views/home.js";
import Pools from "views/pools.js";

import Login from "views/login.js";
import Register from "views/register.js";
import Dashboard from "views/dashboard.js";
import Farms from "views/farms";
import { ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import FAQ from "views/faq.js";

ReactDOM.render(
  <BrowserRouter basename="/">
  <ToastContainer/>
    <Switch>
      <Route path="/faq" component={FAQ} />
      <Route path="/dashboard" component={Dashboard} />
      <Route path="/register" component={Register} />
      <Route path="/login" component={Login} />
      <Route path="/Pools" component={Pools} />
      <Route path="/farms" component={Farms} />
      <Route path="/" component={Farms} />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);


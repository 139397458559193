import React, { useEffect } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { Button, TextField } from '@material-ui/core';
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinksAfterlogin from "components/Header/HeaderLinksAfterlogin.js";
import Footer from "components/Footer/Footer.js";

import styles from "assets/jss/material-kit-react/views/home.js";
import { Link } from "react-router-dom";

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Dashboard(props) {
  const classes = useStyles();
  const { ...rest } = props;

  return (
    <div className="inner_header">
      <Header
        fixed
        color="transparent"
        routes={dashboardRoutes}
        brand={<img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid"/>}
        rightLinks={<HeaderLinksAfterlogin />}
        changeColorOnScroll={{
          height: 100,
          color: "dark"
        }}
        {...rest}
      />
      <ScrollToTopOnMount/>
      <div className={classes.pageHeader + " inner_pageheader"}>
        <div className="dash_top">
          <div className={classes.container}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} className="text-center m-auto">
                <div className="d-flex justify-content-between align-items-center">
                  <h2 className="inner_title text-center">Dashboard</h2>
                  <div className="dash_right">
                    <div className="affiliate_link">
                      <p>My Affiliate Link: <a href="#">#512345</a></p>
                      <div className="affiliate_link_icon">
                        <a href="/#" className=""><img src={require("../assets/images/copy_icon.png")} alt="logo" className="img-fluid"/></a>
                        <a href="/#" className=""><img src={require("../assets/images/share_icon.png")} alt="logo" className="img-fluid"/></a>
                      </div>
                    </div>
                    <p className="last_login">Last Login: 02 Dec 2020 14:40 PM</p>
                  </div>
                </div>
              </GridItem>
            </GridContainer>
          </div>
        </div>
        <div className="container">
          <GridContainer>
            <GridItem xs={12} sm={6} md={4} className="text-center m-auto">
              <div className="dash_panel dash_panel_people">
                <div className="dash_panel_details">
                  <p>NO.OF PEOPLE JOINED</p>
                  <h2>280</h2>
                </div>
              </div>
            </GridItem>
            <GridItem xs={12} sm={6} md={4} className="text-center m-auto">
              <div className="dash_panel dash_panel_trx">
                <div className="dash_panel_details">
                  <p>MY earnings in TRX </p>
                  <h2>3750</h2>
                </div>
              </div>
            </GridItem>
            <GridItem xs={12} sm={6} md={4} className="text-center m-auto">
              <div className="dash_panel dash_panel_usd">
                <div className="dash_panel_details">
                  <p>MY earnings in USD</p>
                  <h2>131.61</h2>
                </div>
              </div>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem sm={12} md={12} lg={3}>
              <div className="dash_left_setion">

                <div className="dash_pool">
                  <div className="dash_pool_circle">
                    <h2>Pool 1</h2>
                  </div>
                  <div className="dash_pool_details">
                    <h2>400TRX</h2>
                    <div className="d-flex align-items-center justify-content-end">
                      <a href="#"><img src={require("../assets/images/reinvest_icon.png")} alt="logo" className="img-fluid mr-2"/></a>
                      <p className="mb-0">Reinvest : 10</p>
                    </div>
                  </div>
                </div>

                <div className="dash_pool">
                  <div className="dash_pool_circle">
                    <h2>Pool 2</h2>
                  </div>
                  <div className="dash_pool_details">
                    <h2>6400TRX</h2>
                    <div className="d-flex align-items-center justify-content-end">
                      <a href="#"><img src={require("../assets/images/reinvest_icon.png")} alt="logo" className="img-fluid mr-2"/></a>
                      <p className="mb-0">Reinvest : 10</p>
                    </div>
                  </div>
                </div>

                <div className="dash_pool dash_pool_disabled">
                  <div className="dash_pool_circle">
                    <h2>Pool 3</h2>
                  </div>
                  <div className="dash_pool_details">
                    <h2>20480TRX</h2>
                    <div className="d-flex align-items-center justify-content-end">
                      <a href="#"><img src={require("../assets/images/reinvest_icon.png")} alt="logo" className="img-fluid mr-2"/></a>
                      <p className="mb-0">Reinvest : 10</p>
                    </div>
                  </div>
                </div>

                <div className="dash_pool dash_pool_disabled">
                  <div className="dash_pool_circle">
                    <h2>Pool 4</h2>
                  </div>
                  <div className="dash_pool_details">
                    <h2>65536TRX</h2>
                    <div className="d-flex align-items-center justify-content-end">
                      <a href="#"><img src={require("../assets/images/reinvest_icon.png")} alt="logo" className="img-fluid mr-2"/></a>
                      <p className="mb-0">Reinvest : 10</p>
                    </div>
                  </div>
                </div>

                <div className="dash_pool dash_pool_disabled">
                  <div className="dash_pool_circle">
                    <h2>Pool 5</h2>
                  </div>
                  <div className="dash_pool_details">
                    <h2>209712TRX</h2>
                    <div className="d-flex align-items-center justify-content-end">
                      <a href="#"><img src={require("../assets/images/reinvest_icon.png")} alt="logo" className="img-fluid mr-2"/></a>
                      <p className="mb-0">Reinvest : 10</p>
                    </div>
                  </div>
                </div>

                <div className="dash_pool dash_pool_disabled">
                  <div className="dash_pool_circle">
                    <h2>Pool 6</h2>
                  </div>
                  <div className="dash_pool_details">
                    <h2>671078.4TRX</h2>
                    <div className="d-flex align-items-center justify-content-end">
                      <a href="#"><img src={require("../assets/images/reinvest_icon.png")} alt="logo" className="img-fluid mr-2"/></a>
                      <p className="mb-0">Reinvest : 10</p>
                    </div>
                  </div>
                </div>

                <div className="dash_pool dash_pool_disabled">
                  <div className="dash_pool_circle">
                    <h2>Pool 7</h2>
                  </div>
                  <div className="dash_pool_details">
                    <h2>2147450.88TRX</h2>
                    <div className="d-flex align-items-center justify-content-end">
                      <a href="#"><img src={require("../assets/images/reinvest_icon.png")} alt="logo" className="img-fluid mr-2"/></a>
                      <p className="mb-0">Reinvest : 10</p>
                    </div>
                  </div>
                </div>

              </div>
            </GridItem>
            <GridItem sm={12} md={12} lg={9}>
              <div className="dash_right_setion">
                <div className="text-center">
                  <Button className="blue_btn">ACTIVE POOL 400 TRX</Button>
                </div>
                <div className="plan_img">
                  <div className="plan_pool_head">
                    <i className="far fa-user"></i>
                  </div>

                  {/* First Row */}
                  <div className="plan_pool_first_row">
                    <div className="plan_pool_head_child">
                      <i className="far fa-user"></i>
                    </div>

                    <div className="plan_pool_head_child">
                      <i className="far fa-user"></i>
                    </div>

                    <div className="plan_pool_head_child plan_inactive">
                      <i className="far fa-user"></i>
                    </div>

                    <div className="plan_pool_head_child plan_inactive">
                      <i className="far fa-user"></i>
                    </div>
                  </div>

                  {/* Second Row */}
                  <div className="plan_pool_second_row">
                    <div>
                      <div className="plan_pool_head_child">
                        <i className="far fa-user"></i>
                      </div>

                      <div className="plan_pool_head_child">
                        <i className="far fa-user"></i>
                      </div>

                      <div className="plan_pool_head_child">
                        <i className="far fa-user"></i>
                      </div>

                      <div className="plan_pool_head_child">
                        <i className="far fa-user"></i>
                      </div>
                    </div>

                    <div>
                      <div className="plan_pool_head_child">
                        <i className="far fa-user"></i>
                      </div>

                      <div className="plan_pool_head_child">
                        <i className="far fa-user"></i>
                      </div>

                      <div className="plan_pool_head_child">
                        <i className="far fa-user"></i>
                      </div>

                      <div className="plan_pool_head_child">
                        <i className="far fa-user"></i>
                      </div>
                    </div>

                    <div>
                      <div className="plan_pool_head_child plan_inactive">
                        <i className="far fa-user"></i>
                      </div>

                      <div className="plan_pool_head_child plan_inactive">
                        <i className="far fa-user"></i>
                      </div>

                      <div className="plan_pool_head_child plan_inactive">
                        <i className="far fa-user"></i>
                      </div>

                      <div className="plan_pool_head_child plan_inactive">
                        <i className="far fa-user"></i>
                      </div>
                    </div>

                    <div>
                      <div className="plan_pool_head_child plan_inactive">
                        <i className="far fa-user"></i>
                      </div>
 
                      <div className="plan_pool_head_child plan_inactive">
                        <i className="far fa-user"></i>
                      </div>

                      <div className="plan_pool_head_child plan_inactive">
                        <i className="far fa-user"></i>
                      </div>

                      <div className="plan_pool_head_child plan_inactive">
                        <i className="far fa-user"></i>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="plan_note">
                  <div className="mt-3 pt-3">
                    <h1><span>TIER 1:</span> 1280 TRX by 4 = 5120 TRX.</h1>
                    <h2>2560 TRX is used to open up Tier 2</h2>
                  </div>
                  <div className="mt-3">
                    <h1><span>TIER 2:</span> 2560 TRX by 16 = 40960 TRX. </h1>
                    <h2>20480 TRX is used to Activate Pool 3 and 6400TRX will be used to reactivate Pool 2 again (Recycle).</h2>
                  </div>
                </div>

                
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <Footer/>
    </div>
  );
}

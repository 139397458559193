import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import styles from "assets/jss/material-kit-react/views/loginPage.js";
import { Link } from "react-router-dom";

import {KeyboardArrowRight} from '@material-ui/icons';

const useStyles = makeStyles(styles);
const dashboardRoutes = [];
export default function Login(props) {
  
  const { ...rest } = props;
  const classes = useStyles();
  return (
    <div>
      <div className="auth_panel">
        <div className={classes.container + " auth_padding_container"}>
          <GridContainer justify="center">
            <GridItem sm={12} md={10} lg={6} className="m-auto">
              <div className="text-center">
                <Link to="/"><img src={require("../assets/images/banner_logo.png")} alt="logo" className="img-fluid auth_logo"/></Link>
              </div>
              <form className="auth_form">
                <div className="auth_main_div">
                  <div className="login_top">
                    <h2>The entrance to the office</h2>
                    <Button className="blue_btn"><span>REGISTER</span></Button>
                  </div>
                  <div className="login_bottom">
                    <h2>IF YOU HAVE AN ACCOUNT</h2>
                    <Button className="auth_btn"><span>AUTHORIZATION</span></Button>
                  </div>
                </div>
              </form>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      
      
    </div>
  );
}

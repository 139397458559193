import React, { useEffect ,useRef} from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";


// core components
import Header from "components/Header/Header.js";
import FooterHome from "components/Footer/FooterHome.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import styled from "styled-components";
import axios from "axios"
import styles from "assets/jss/material-kit-react/views/home.js";
import { Link } from "react-router-dom";
import { Button, Tooltip, Accordion, AccordionDetails, AccordionSummary } from "@material-ui/core";
import Countdown from "react-countdown";
import moment from "moment";
import { HelpOutline, ArrowDropDown, Launch } from '@material-ui/icons';
import icoabi from "../ABI/ICO2ABI.json"
import stackabi from "../ABI/STACKABI.json"
import variableabi from "../ABI/VARIABLEABI.json"
import {
  getCurAddr,
} from '../actions/users';
import Web3 from 'web3';
import '@metamask/legacy-web3'
import config from '../config/config';
import { toast } from 'react-toastify';
toast.configure();
let toasterOption = config.toasterOption;
const useStyles = makeStyles(styles);
var mynetwork = config.NETWORKVERSION
const dashboardRoutes = [];
const Icon = styled(props => (
  <div {...props}>
    <div className="minus">-</div>
    <div className="plus">+</div>
  </div>
))`
  & > .plus {
    display: block;
    color: #24272c;
    font-size: 42px;
    background-color: #383b40;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  & > .minus {
    display: none;
    color: #24272c;
    font-size: 42px;
    background-color: #383b40;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
  }
  .Mui-expanded & > .minus {
    display: flex;
  }
  .Mui-expanded & > .plus {
    display: none;
  }
`;


// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function LandingPage(props) {
  const classes = useStyles();
  const timerRef = useRef(null);
  
const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#fff',
    color: '#646e88',
    maxWidth: 220,
    fontSize: "13px",
    fontFamily: "'Montserrat', sans-serif",
    lineHeight: "20px",
    border: '1px solid #fff',
    boxShadow: '-4px 5px 10px 2px rgb(0 0 0 / 20%)'
  },
}))(Tooltip);
  const [expanded, setExpanded] = React.useState('panel1');

  const handleChangeFaq = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const renderer = ({ days, Month, Year, hours, minutes, seconds, completed }) => {
        if (completed) {
            return <span></span>
        } else {
            return <span>{days}d {hours}h {minutes}m {seconds}s</span>;
        }
    };

  const { ...rest } = props;
  const [accounts, setAccounts]     = React.useState("");
  const [harvestprogress,setharvestprogress] = React.useState(false)
  const [claimprogress,setclaimprogress] = React.useState(false)
  const [apy,setapy] = React.useState(0)
  const [balance,setbalance] = React.useState(0)
  const [amount,setamount] = React.useState(0)
  const [earnamt,setearnamt] = React.useState(0)
  const [inprogress,setinprogress] = React.useState(false)
  const [myallowance,setallowance] = React.useState(0)
  const [stakedamt, setstakedamt] = React.useState(0)
  const [withamount,setwithamount] = React.useState(0)
  const [approveprogress,setapproveprogress] = React.useState(false)
  const [unstakestatus,setunstakestatus] = React.useState(false)
  const [liquitity,setliquitity] = React.useState(0)
  const [mpadprice,setmpadprice] = React.useState(0)
  const [timer,settimer] = React.useState(0)
  const [unstakedamt,setunstakedamt] = React.useState(0)
  const [tvlvalue,settvlvalue] = React.useState(0)
   useEffect(() => {
    getinit();
    const interval = setInterval(async() => {
      await gettvlvalue()
      if(localStorage.getItem("address") && localStorage.getItem("address")!=null && localStorage.getItem("address")!=undefined && localStorage.getItem("address")!=""){
        getharvest(localStorage.getItem("address"))
        console.log('This will run every second!');
      }
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  async function gettvlvalue(){
      const resp = await axios.post(
      config.baseurl + `api/tvlvalue`,{page:1}
    );
      console.log(resp,"=================tvlvalue")
      if(resp && resp.data && resp.data.data && resp.data.data[0] && resp.data.data[0].totalamount){
        settvlvalue(resp.data.data[0].totalamount)
      }else{
        settvlvalue(0)
      }
  }

  async function getinit(){
    try{
      if(localStorage.getItem("iuldmitakserrf")){
         var curAddr = await getCurAddr();
         if(curAddr == localStorage.getItem("address")){
          setAccounts(curAddr)
          localStorage.setItem("iuldmitakserrf",'yes');
          localStorage.setItem("address",curAddr);
          getharvest(curAddr);
         }else{
          localStorage.clear();
          window.location.reload()
         }
      }
      }catch(err){
        
      }
      getapy()
      getliqiuity()
      getfarmliqiuity()
    }

    async function getliqiuity(){
      axios.get('https://api.pancakeswap.info/api/v2/tokens/0x11d1ac5ec23e3a193e8a491a198f5fc9ee715839')
      .then(async(response)=>{
        console.log(response.data.data.price,"========response.data.data.price")
        if(response && response.data && response.data.data && response.data.data.price){
          setmpadprice(response.data.data.price)
        }
      })
  }

  async function getfarmliqiuity(){
    if (window.ethereum) {
      var web3 = new Web3(window.ethereum);
      try {
        if (typeof web3 !== 'undefined') {
          var icoContract = new web3.eth.Contract(icoabi, config.ICOCONTRACT);
          var stackcontract = new web3.eth.Contract(stackabi, config.STACKINGCONTRACT);
          var variable1 = await icoContract.methods.balanceOf(config.STACKINGCONTRACT).call();
          var variable2 = await icoContract.methods.totalSupply().call();
          var variable3 = parseFloat(variable1)/parseFloat(variable2);
          var variablecontract = await icoContract.methods.token0().call();
          var newvarcontract = new web3.eth.Contract(variableabi, variablecontract);
          var variablebalance = await newvarcontract.methods.balanceOf(config.ICOCONTRACT).call();
          var quoteTokenBalance = parseFloat(variablebalance)/1000000000000000000; 
          var variable4 = parseFloat(variable3)*parseFloat(quoteTokenBalance)
          var finalliquity = parseFloat(variable4)*2
          setliquitity(finalliquity)
        }
      }catch(err){
        console.log(err,"======================err4")
      }
    }
  }

  async function getapy(){
    console.log("inapy")
    if (window.ethereum) {
      var web3 = new Web3(window.ethereum);
      try {
        if (typeof web3 !== 'undefined') {
          var icoContract = new web3.eth.Contract(icoabi, config.ICOCONTRACT2);
          var stackcontract = new web3.eth.Contract(stackabi, config.STACKINGCONTRACT);
          console.log(stackcontract,"============stackcontrct")
          var apyc =  await stackcontract.methods.multipadAPY().call()
          setapy(apyc/1000000000000)
        }
      }catch(err){
        console.log(err,"======================err4")
      }
    }
  }

  async function connectMetamask(){
    if (window.ethereum) {
      var web3 = new Web3(window.ethereum);
      try {
        if (typeof web3 !== 'undefined') {
        window.ethereum.enable().then(async function() {
        const web3  = new Web3(window.web3.currentProvider)
         if(window.web3.currentProvider.networkVersion == mynetwork){
        if (window.web3.currentProvider.isMetaMask === true) {
          await web3.eth.getAccounts(async function(error, result) {
            setAccounts(result[0]);
            localStorage.setItem("iuldmitakserrf",'yes');
            localStorage.setItem("address",result[0]);
            getharvest(result[0])
            window.location.reload()
          })
        }
        }else {
              toast.warning("Please Connect to BNB Network", toasterOption);
        }
        })
        }else{
             toast.warning("Please Add Metamask External", toasterOption);
        }
      }catch(err){}
     }else{
             toast.warning("Please Add Metamask External", toasterOption);
     }
  }

  async function deposit(){
    setinprogress(true)
    if(amount!=null && amount!=undefined && amount!="" && parseFloat(amount)>0 ){
      if(parseFloat(amount)<=parseFloat(balance)){
    if (window.ethereum) {
      var web3 = new Web3(window.ethereum);
      try {
        if (typeof web3 !== 'undefined') {
        window.ethereum.enable().then(async function() {
        const web3  = new Web3(window.web3.currentProvider)
         if(window.web3.currentProvider.networkVersion == mynetwork){
        if (window.web3.currentProvider.isMetaMask === true) {
          await web3.eth.getAccounts(async function(error, result) {
            setAccounts(result[0]);
            localStorage.setItem("iuldmitakserrf",'yes');
            localStorage.setItem("address",result[0]);
            var icoContract = new web3.eth.Contract(icoabi, config.ICOCONTRACT2);
            var stackcontract = new web3.eth.Contract(stackabi, config.STACKINGCONTRACT);
            var Tamount = await web3.utils.toWei((amount).toString());
             Tamount = await convert(Tamount)
              await stackcontract.methods.deposit(1,Tamount).send({from: result[0]})
             .then(async (deposituccess) => {
              toast.success("Deposited Successfully", toasterOption);
              window.location.reload();
             })
             .catch((errors) => {
              setinprogress(false)
              toast.warning("Failed Tryagain Later", toasterOption);
             })
          })
        }else {
              setinprogress(false)
              toast.warning("Please Connect to BNB Network", toasterOption);
        }
        }else {
              setinprogress(false)
              toast.warning("Please Connect to BNB Network", toasterOption);
        }
        })
        }else{
             setinprogress(false)
             toast.warning("Please Add Metamask External", toasterOption);
        }
      }catch(err){
        setinprogress(false)
        toast.warning("Failed Tryagain Later", toasterOption);
      }
     }else{ 
             setinprogress(false)
             toast.warning("Please Add Metamask External", toasterOption);
     }
    }else{
             setinprogress(false)
             toast.warning("Insufficient Amount", toasterOption);
     }
   }else{
             setinprogress(false)
             toast.warning("Please Enter Valid Amount", toasterOption);
     }
  }

  function convert(n){
      var sign = +n < 0 ? "-" : "",
      toStr = n.toString();
      if (!/e/i.test(toStr)) {
        return n;
      }
      var [lead,decimal,pow] = n.toString()
       .replace(/^-/,"")
       .replace(/^([0-9]+)(e.*)/,"$1.$2")
       .split(/e|\./);
       return +pow < 0
      ? sign + "0." + "0".repeat(Math.max(Math.abs(pow)-1 || 0, 0)) + lead + decimal
      : sign + lead + (+pow >= decimal.length ? (decimal + "0".repeat(Math.max(+pow-decimal.length || 0, 0))) : (decimal.slice(0,+pow)+"."+decimal.slice(+pow)))
    }

  async function getharvest(myadd){
    var web3 = new Web3(window.ethereum);
    if (typeof web3 !== 'undefined') {
      var icoContract = new web3.eth.Contract(icoabi, config.ICOCONTRACT2);
      var bal =  await icoContract.methods.balanceOf(myadd).call()
      setbalance((parseFloat(bal)/1000000000000000000).toFixed(4))
      var allowance = await icoContract.methods.allowance(myadd,config.STACKINGCONTRACT).call()
      setallowance(parseFloat(allowance)/1000000000000000000)
      var stackcontract = new web3.eth.Contract(stackabi, config.STACKINGCONTRACT);
      var pendingamt = await stackcontract.methods.pendingMultiPad(1,myadd).call();
      var interval = await stackcontract.methods.timeInterval().call()
      setearnamt((parseFloat(pendingamt)/1000000000000000000).toFixed(8))
      var userdet = await stackcontract.methods.userInfo(1,myadd).call();
      console.log(userdet,"===================userdet")
      var resultdata = await JSON.parse(JSON.stringify(userdet))
      if(resultdata && resultdata.amount && resultdata.amount!=null && resultdata.amount!=undefined && resultdata.amount!="" && parseFloat(resultdata.amount)>0){
        setstakedamt((parseFloat(resultdata.amount)/1000000000000000000).toFixed(4))
      }else{
        setstakedamt(0)
      }
      if(resultdata && resultdata.unstaked && resultdata.unstaked!=null && resultdata.unstaked!=undefined && resultdata.unstaked!="" && parseFloat(resultdata.unstaked)>0){
        setunstakedamt((parseFloat(resultdata.unstaked)/1000000000000000000).toFixed(4))
      }else{
        setunstakedamt(0)
      }
      if(resultdata && resultdata.unstakeUntil && resultdata.unstakeUntil!=null && resultdata.unstakeUntil!=undefined && resultdata.unstakeUntil!="" ){
        var datee =  moment.unix((parseFloat(resultdata.unstakeUntil))).format("DD MMM YYYY hh:mm a")
        var mydate = new Date(datee)
        settimer(mydate)
        var a = moment(mydate)
        var b = moment();
        var c = a.diff(b)
        if(parseFloat(c)>0){
          setunstakestatus(true)
        }else{
          setunstakestatus(false)
        }
      }else{
        settimer(0)
        setunstakestatus(false)
      }
    }else{
         toast.warning("Please Add Metamask External", toasterOption);
    }
  }

  async function proceedharvest(){
     setharvestprogress(true)
     if (window.ethereum) {
      var web3 = new Web3(window.ethereum);
      try {
        if (typeof web3 !== 'undefined') {
        window.ethereum.enable().then(async function() {
        const web3  = new Web3(window.web3.currentProvider)
         if(window.web3.currentProvider.networkVersion == mynetwork){
        if (window.web3.currentProvider.isMetaMask === true) {
          await web3.eth.getAccounts(async function(error, result) {
            setAccounts(result[0]);
            localStorage.setItem("iuldmitakserrf",'yes');
            localStorage.setItem("address",result[0]);
            var icoContract = new web3.eth.Contract(icoabi, config.ICOCONTRACT2);
            var stackcontract = new web3.eth.Contract(stackabi, config.STACKINGCONTRACT);
             await stackcontract.methods.Harvest(1).send({from: result[0]})
             .then(async (successharvest) => {
              setharvestprogress(false)
               toast.success("Harvested Successfully", toasterOption);
               window.location.reload()
             })
             .catch((errors) => {
              setharvestprogress(false)
              toast.warning("Failed TryAgain Later", toasterOption);
             })
            
          })
        }else{
          setharvestprogress(false)
          toast.warning("Please Add Metamask External", toasterOption);
        }
        }else {
            setharvestprogress(false)
            toast.warning("Please Connect to BNB Network", toasterOption);
        }
        })
        }else{
           setharvestprogress(false)
           toast.warning("Please Add Metamask External", toasterOption);
        }
      }catch(err){
        setharvestprogress(false)
        toast.warning("Please Add Metamask External", toasterOption);
      }
     }else{
          setharvestprogress(false)
          toast.warning("Please Add Metamask External", toasterOption);
     }
  }

  async function proceedclaim(){
     setclaimprogress(true)
     if (window.ethereum) {
      var web3 = new Web3(window.ethereum);
      try {
        if (typeof web3 !== 'undefined') {
        window.ethereum.enable().then(async function() {
        const web3  = new Web3(window.web3.currentProvider)
         if(window.web3.currentProvider.networkVersion == mynetwork){
        if (window.web3.currentProvider.isMetaMask === true) {
          await web3.eth.getAccounts(async function(error, result) {
            setAccounts(result[0]);
            localStorage.setItem("iuldmitakserrf",'yes');
            localStorage.setItem("address",result[0]);
            var icoContract = new web3.eth.Contract(icoabi, config.ICOCONTRACT2);
            var stackcontract = new web3.eth.Contract(stackabi, config.STACKINGCONTRACT);
             await stackcontract.methods.claim(1).send({from: result[0]})
             .then(async (successclaim) => {
              setclaimprogress(false)
               toast.success("UnStaked Successfully", toasterOption);
               window.location.reload()
             })
             .catch((errors) => {
              setclaimprogress(false)
              toast.warning("Failed TryAgain Later", toasterOption);
             })
            
          })
        }else{
          setclaimprogress(false)
          toast.warning("Please Add Metamask External", toasterOption);
        }
        }else {
            setclaimprogress(false)
            toast.warning("Please Connect to BNB Network", toasterOption);
        }
        })
        }else{
           setclaimprogress(false)
           toast.warning("Please Add Metamask External", toasterOption);
        }
      }catch(err){
        setclaimprogress(false)
        toast.warning("Please Add Metamask External", toasterOption);
      }
     }else{
          setclaimprogress(false)
          toast.warning("Please Add Metamask External", toasterOption);
     }
  }

  const amountchange = (e) => {
    console.log(e.target.value,"=========amountchange")
    setamount(e.target.value)
  }

  async function setmax(){

    setamount(parseInt(balance))
  }

  async function approvefunction(){
    setapproveprogress(true)
     if (window.ethereum) {
      var web3 = new Web3(window.ethereum);
      try {
        if (typeof web3 !== 'undefined') {
        window.ethereum.enable().then(async function() {
        const web3  = new Web3(window.web3.currentProvider)
         if(window.web3.currentProvider.networkVersion == mynetwork){
        if (window.web3.currentProvider.isMetaMask === true) {
          await web3.eth.getAccounts(async function(error, result) {
            setAccounts(result[0]);
            localStorage.setItem("iuldmitakserrf",'yes');
            localStorage.setItem("address",result[0]);
            var Tamount = await web3.utils.toWei((1000000000000000000).toString());
             Tamount = await convert(Tamount)
            var icoContract = new web3.eth.Contract(icoabi, config.ICOCONTRACT2);
            var stackcontract = new web3.eth.Contract(stackabi, config.STACKINGCONTRACT);
             await icoContract.methods.approve(config.STACKINGCONTRACT,Tamount).send({from: result[0]})
             .then(async (approvesuccess) => {
              setapproveprogress(false)
              toast.success("Approved Successfully", toasterOption);
              window.location.reload()
              })
             .catch((error) => {
              setapproveprogress(false)
              toast.warning("Failed Tryagain Later", toasterOption);
             })
            
          })
        }else{
          setapproveprogress(false)
          toast.warning("Please Connect to BNB Network", toasterOption);
       
        }
        }else {
            setapproveprogress(false)
            toast.warning("Please Connect to BNB Network", toasterOption);
        }
        })
        }else{
           setapproveprogress(false)
           toast.warning("Please Add Metamask External", toasterOption);
        }
      }catch(err){
        setapproveprogress(false)
        toast.warning("Failed Try Again Later", toasterOption);
      }
     }else{
          setapproveprogress(false)
          toast.warning("Please Add Metamask External", toasterOption);
     }
  }

  async function setwithmax(){
    setwithamount(parseInt(stakedamt))
  }

  const withamountchange = (e) => {
    setwithamount(e.target.value)
  }

  async function withdraw(){
    setinprogress(true)
    if(withamount && withamount!="" && withamount!=null && withamount!=undefined && parseFloat(withamount)>0){
      if(parseFloat(withamount)<=parseFloat(stakedamt)){
     if (window.ethereum) {
      var web3 = new Web3(window.ethereum);
      try {
        if (typeof web3 !== 'undefined') {
        window.ethereum.enable().then(async function() {
        const web3  = new Web3(window.web3.currentProvider)
         if(window.web3.currentProvider.networkVersion == mynetwork){
        if (window.web3.currentProvider.isMetaMask === true) {
          await web3.eth.getAccounts(async function(error, result) {
            setAccounts(result[0]);
            localStorage.setItem("iuldmitakserrf",'yes');
            localStorage.setItem("address",result[0]);
            var withdrawamt = await web3.utils.toWei((withamount).toString());
             withdrawamt = await convert(withdrawamt)
            var icoContract = new web3.eth.Contract(icoabi, config.ICOCONTRACT2);
            var stackcontract = new web3.eth.Contract(stackabi, config.STACKINGCONTRACT);
             await stackcontract.methods.withdraw(1,withdrawamt).send({from: result[0]})
             .then(async (success) => {
               toast.success("Withdraw Successfully", toasterOption);
               window.location.reload();
             })
             .catch((errors) => {
              setinprogress(false)
              toast.warning("Failed Try again Later", toasterOption);

             })
            
          })
        }else {
          setinprogress(false)
            toast.warning("Please Connect to BNB Network", toasterOption);
        }
        }else {
          setinprogress(false)
            toast.warning("Please Connect to BNB Network", toasterOption);
        }
        })
        }else{
          setinprogress(false)
           toast.warning("Please Add Metamask External", toasterOption);
        }
      }catch(err){
       setinprogress(false)
       toast.warning("Failed Try again Later", toasterOption);
      }
     }else{
      setinprogress(false)
          toast.warning("Please Add Metamask External", toasterOption);
     }
     }else{
      setinprogress(false)
    toast.warning("Insufficient Balance", toasterOption);
   }
   }else{
    setinprogress(false)
    toast.warning("Please Enter Valid Amount", toasterOption);
   }
  }

  window.addEventListener('load', (event) => {
    event.preventDefault();
    console.log('addEventListener',event);
    if(window.ethereum) {
      window.ethereum.on('accountsChanged', function (accounts) {
        window.location.reload(false);
        if(timerRef.current) {
          clearTimeout(timerRef.current);
        }
        timerRef.current = setTimeout(() => {
          connectMetamask();
        }, 1000);
      })

      window.ethereum.on('networkChanged', function (networkId) {
        if(networkId == mynetwork){
          window.location.reload(false);
          if(timerRef.current) {
            clearTimeout(timerRef.current);
          }
          timerRef.current = setTimeout(() => {
            connectMetamask();
          }, 1000);
        }
        else {
          connectMetamask();
        }
      })
    }
  })

  return (
    <div>      
      <Header className="header"
        color="transparent"
        routes={dashboardRoutes}
        brand={<img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid" />}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 100,
          color: "dark",
        }}
        {...rest}
      />    
      <ScrollToTopOnMount/>
      <div className="">
        <div className={classes.container}>
          
        </div>
      </div>

      <div className="main padding_top_g">

      <div>
        <div className={classes.container}>

          {/* <GridContainer>
            <GridItem lg={12}>
            <div className="pools_filter" data-aos="fade-up" data-aos-duration="2000">
                    <div className="pools_filter_left">
                      <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" id="customCheck1" />
                        <label class="custom-control-label" for="customCheck1">Staked only</label>
                      </div>
                      <div className="pool_btn_grp">
                        <Button className="active">Live</Button>
                        <Button>Finished</Button>
                      </div>
                    </div>

                    <div className="pools_filter_right">
                      <select className="custom-select">
                        <option>Sort By</option>
                        <option>APR</option>
                        <option>Earned</option>
                        <option>Total staked</option>
                      </select>
                      <input className="pool_search" placeholder="Search Farms" />
                      <div className="view_btn_grp">
                        <Button className="list_view_btn"><i class="bi bi-list-ul"></i></Button>
                        <Button className="grid_view_btn active"><i class="bi bi-grid"></i></Button>
                      </div>
                    </div>
                  </div>
            </GridItem>
          </GridContainer> */}


          <GridContainer className="mt-5 align-items-center justify-content-center">
           <GridItem md={4} sm={12} md={6} lg={5}>
            <h6>Total Value Locked {parseFloat(tvlvalue).toFixed(2)} in MPAD BNB LP and MPAD</h6>
              <div className="grid_view_single" data-aos="fade-up" data-aos-duration="2000">
                <div className="grid_view_single_first">
                  <div>
                    <h2>MPAD</h2>
                    {/*<p>Automatic restaking</p>*/}
                  </div>
                  <div className="icon_section_new">
                  <div className="primer_img">
                  <img src={require("../assets/images/fav.png")} alt="Icon" />
                  </div>
                  
                  </div>
                </div>
                <hr />
                <div className="grid_view_single_second">
                  <div className="d-flex align-items-center justify-content-between mb-3">
                    <p>APY</p>
                    <div className="d-flex align-items-center"><p>{apy}%</p></div>
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <div>
                      <p>Earned</p>
                      <h4>{earnamt}</h4>
                    </div>
                          <Button className="harvest_btn disabled_btn" onClick={harvestprogress?"":()=>proceedharvest()}>{harvestprogress?"In Progress":"Harvest"}</Button>
                    
                  </div>
                  <div className="count_add_btn d-flex align-items-center justify-content-between mb-2">
                    <label className="mb-2">Start Earning</label>
                  </div>

                  <div className="count_add_btn d-flex align-items-center justify-content-between mb-2">
                  <div>UnStaked Amount : {unstakedamt}</div>
                  <div className="d-flex align-items-center">
                  {unstakestatus?
                    <div className="countdown_panel">
                    <div className="countdown_panel_div">
                      <Countdown
                        date={new Date(timer)}
                        autoStart={true}
                        onStart={() => new Date()}
                        renderer={renderer}
                      ></Countdown>
                    </div>
                    </div>
                    :parseFloat(unstakedamt)>0
                    ?
                    <Button className="harvest_btn disabled_btn" onClick={claimprogress?"":()=>proceedclaim()}>{claimprogress?"In Progress":"Claim"}</Button>
                    :
                    ""
                    }
                    </div>
                  </div>

                  <div className="count_add_btn d-flex align-items-center justify-content-between mb-2">
                  <div>Staked Amount : {stakedamt}</div>
                  {parseFloat(stakedamt)>0?
                  <div className="stake_btn_grps">
                  {unstakestatus ?
                    <Button className="primary_btn " disabled>-</Button>
                  :
                   <Button className="primary_btn " data-toggle="modal" data-target="#withdraw_modal">-</Button>
                  }
                  <Button className="primary_btn " data-toggle="modal" data-target="#Tokens_modal">+</Button>
                  </div>
                  :
                  ""
                  }
                  </div>
                  {accounts!="" ?
                  parseFloat(myallowance)<=0?
                  <Button className="primary_btn blue_btn" onClick={approveprogress?"":()=>approvefunction()}>{approveprogress?"In Progress": "Approve Contract"}</Button>
                  :
                  parseFloat(stakedamt)>0?
                  ""
                  :
                  <Button className="primary_btn blue_btn"  data-toggle="modal" data-target="#Tokens_modal">Stake</Button>
                  :
                  <Button className="primary_btn blue_btn" onClick={()=>connectMetamask()}>Connect Wallet</Button>
                  }
                </div>
                <hr />
                <div className="grid_view_single_third">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center">
                      <Button className="manual_btn"><img src={require("../assets/images/price_refresh_icon.png")} className="mr-2" />Manual</Button>
                      <HtmlTooltip className="tooltip_content"
                        title={
                          <React.Fragment>
                            <p className="tooltip_content">Your trasaction will revert if the price changes unfavourably by more than this percentage</p>
                          </React.Fragment>
                        }
                      >
                        <HelpOutline className="tooltip_icon" />
                      </HtmlTooltip>
                    </div>
                    <a class="accordian_link" data-toggle="collapse" href="#collapseExample1" role="button" aria-expanded="false" aria-controls="collapseExample">
                      Details
                    </a>
                  </div>
                  <div class="collapse" id="collapseExample1">
                    <div>
                      <div className="d-flex justify-content-between align-items-center">
                        <h3>Total Liquidity:</h3>
                        <h4>$ {(parseFloat(stakedamt)*parseFloat(mpadprice)).toFixed()>0?(parseFloat(stakedamt)*parseFloat(mpadprice)).toFixed():(parseFloat(stakedamt)*parseFloat(mpadprice)).toFixed(4)}</h4>
                      </div>
                      <div className="d-flex justify-content-between align-items-start">
                        <h3></h3>
                        <div className="text-right">
                          <p><a href={config.CONTRACTURL+config.ICOCONTRACT2} target="_blank">View Contract <i class="bi bi-box-arrow-up-right"></i></a></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </div>

        <FooterHome />
           {/*  ROI Modal */}
      <div className="modal fade primary_modal toledbn" data-backdrop="static" id="Tokens_modal" tabIndex="-1" role="dialog" aria-labelledby="roi_modal" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header pt-1 mb-3 position">
              <h5 className="modal-title" id="Tokens_modal">Deposit</h5>
              <button type="button" className="close close_new" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
             <div className="input_panel">
                  <div className="d-flex justify-content-between align-items-center">
                    <label>Bal: {balance}</label>
                    </div>  
                    <div className="d-flex justify-content-between align-items-center home_inp_panel">
                      <input type="number"  className="custom_inp" min="0" onChange={amountchange} value={amount}/>
                      <Button className="harvest_btn" onClick={()=>setmax()}>Max</Button>
                    </div>                  
             </div>
             <div className="mt-3 d-flex justify-content-between align-items-center">
               <Button className="primary_btn blue_btn mr-3"  data-dismiss="modal" aria-label="Close">Cancel</Button> 
               <Button className="primary_btn blue_btn" onClick={accounts!=""?()=>deposit():()=>connectMetamask()}>{inprogress ? "In Progress" : "Confirm"}</Button>                       
             </div>
            
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade primary_modal toledbn" data-backdrop="static" id="withdraw_modal" tabIndex="-1" role="dialog" aria-labelledby="roi_modal" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header pt-1 mb-3 position">
              <h5 className="modal-title" id="withdraw_modal">Withdraw</h5>
              <button type="button" className="close close_new" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
             <div className="input_panel">
                  <div className="d-flex justify-content-between align-items-center">
                    <label>Bal: {stakedamt}</label>
                    </div>  
                    <div className="d-flex justify-content-between align-items-center home_inp_panel">
                      <input type="number"  className="custom_inp"  onChange={withamountchange}  value={withamount} />
                      <Button className="harvest_btn" onClick={()=>setwithmax()}>Max</Button>
                    </div>                  
             </div>
             <div className="mt-3 d-flex justify-content-between align-items-center">
               <Button className="primary_btn blue_btn mr-3"  data-dismiss="modal" aria-label="Close">Cancel</Button> 
               <Button className="primary_btn blue_btn" onClick={accounts!=""?()=>withdraw():()=>connectMetamask()}>{inprogress ? "In Progress" : "Confirm"}</Button>                       
             </div>
            
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade primary_modal toledbn" data-backdrop="static" id="Connet_w" tabIndex="-1" role="dialog" aria-labelledby="roi_modal" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header pt-1 mb-3 position">
              <h5 className="modal-title" id="Connet_w">Connect to a wallet</h5>
              <button type="button" className="close close_new" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
            <div className="wallet_panel_div">
                <div className="wallet_panel">
                  <h2>Metamask</h2>
                  <img src={require("../assets/images/metamask_icon.png")} className="mr-2" />
                  </div>                    
            </div>
            
            
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
  );
}

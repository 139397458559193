/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";

import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";

import styles from "assets/jss/material-kit-react/components/footerStyle.js";

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const classes = useStyles();
  const { whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  return (
    <footer className={footerClasses + " footer_home"}>
      <div className={classes.container}>
        <div className="row footer_bottom_home">
          {/* <div className="text-center">
            <p>Smart-Contract Address: </p>
            <Link to="/" className="ft_contract">0x5acc84a3e955Bdd76467d3348077d003f00fFB97</Link>
          </div>
          <div className="footer_social_links">
            <ul>
              <li><a href="#" target="_blank"><i className="fab fa-facebook-f"></i></a></li>
              <li><a href="#" target="_blank"><i className="fab fa-twitter"></i></a></li>
              <li><a href="#" target="_blank"><i className="fab fa-linkedin-in"></i></a></li>
              <li><a href="#" target="_blank"><i className="fab fa-instagram"></i></a></li>
              <li><a href="#" target="_blank"><i className="fab fa-youtube"></i></a></li>
            </ul>
          </div> */}
          <div className="col-lg-6">
            <div className="first_secti">
            <img src={require("../../assets/images/logo.png")} alt="logo" className="img-fluid logo_n" />
            <div className="footer-text">
             MultiPad is a community-governed decentralized multi-chain powered launchpad, enabling upcoming promising projects to raise funds.
            </div>
            <div className="footer-icons">
              <a href="https://twitter.com/multi_pad" target="_blank">
              <i class="fab fa-twitter"></i>
              </a>
              <a href="https://telegram.me/multipad_official" target="_blank">
              <i class="fas fa-paper-plane"></i>
              </a>
              <a href="https://medium.com/@multipad.official" target="_blank">
              <img src={require("../../assets/images/Medium_i.svg")} alt="logo" className="img-fluid img_width_s_log" />
              </a>
              </div>
              </div>
            </div>
            <div className="col-lg-4">
                <div className="footer_link_new">
                  <h3>Useful Links</h3>
                  <ul>
                    <li><a href="https://multipad.co/#about" target="_blank">About Us</a></li>
                    <li><a href="https://multipad.co/launch-app" target="_blank">Launch App</a></li>
                    <li><a href="https://docs.google.com/forms/d/e/1FAIpQLSerz7SIGT-XG91WMBI1-bVpmouiBVNt0B1iW8z1VdLkIxRrHQ/viewform" target="_blank">Launch Your Project</a></li>
                    <li><a href="https://multipad.gitbook.io/multipad/" target="_blank">Documentation</a></li>

                  </ul>
                </div>

            </div>
            <div className="col-lg-2">
              <a href="https://github.com/Quillhash/Audit_Reports/blob/master/Multipad%20Smart%20Contract%20Audit%20Report%20-%20QuillAudits.pdf" target="_blank" className="calss_footer_link">
                <img src="https://multipad.co/static/media/quillaudit-bg.4480e73d.png" className="bottm_label"/>
              </a>
            </div>
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  whiteFont: PropTypes.bool
};
